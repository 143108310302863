.header {
  height: 10vh;
  justify-content: right;
  display: flex;
  text-align: right;
  padding: 0rem 3rem;
}

.header * {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

a > svg {
  fill: #000000;
  height: 3rem;
  width: 3rem;
}

a > svg:hover {
  fill: #0d82ff;
}
