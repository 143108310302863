.App {
  background-image: url('./resources/background-image.png');
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Anonymous Pro', monospace;
}

.body {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -10vh;
}

.App__logo {
  height: 50vmin;
  pointer-events: none;
}

.App__try-now-button {
  display: inline-block;
  padding: 1.3rem 3rem;
  color: inherit;
  text-decoration: none;
  border: 2px solid;
  border-radius: 10rem;
  transition: all 0.3s;
  font-weight: 500;
  outline: none;
  color: #fff;
  background: #383838;
  border-color: #383838;
  font-family: 'Anonymous Pro';
  font-size: medium;
}

.App__try-now-button:hover,
.App__try-now-button:focus {
  text-decoration: none;
  transform: translateY(-3px);
  box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.2);
}

.App__try-now-button:disabled {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.3);
  cursor: default;
  box-shadow: none;
  transform: none;
  -webkit-transform: none;
}

.App__error-message {
  width: 60vw;
  line-height: 1.5em;
  padding-top: 2em;
}

.App__info-message {
  width: 60vw;
  line-height: 1.5em;
  padding-top: 2em;
}

.App__footer {
  display: flex;
  padding-bottom: 2em;
  position: absolute;
  bottom: 0;
}

.App__legal__footer {
  display: flex;
  padding-bottom: 3em;
  position: absolute;
  bottom: 0;
}

.footer__divider {
  border-left:1px solid #38546d;
  align-self: center;
  height: 5rem;
}

.footer__section__left {
  margin-right: 1em;
  width: 20em;
  align-self: center;
  text-align: right;
}

.footer__section__middle {
  margin-left: 1em;
  margin-right: 1em;
  text-align: center;
  align-self: center;
}

.footer__section__right {
  margin-left: 1em;
  width: 20em;
  align-self: center;
  text-align: left;
}

.Loading__description {
  padding-top: 3em;
}
